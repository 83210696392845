import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import styles from './navigation.module.scss'
import hamburger from '../../images/menu-button.svg'
import cancel from '../../images/cancel.svg'
import phone from '../../images/phone-receiver.svg'
import mobileLogo from '../../images/motor-injury-helpline-horizontal.png'

class Navigation extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      navigationOpen: false,
      foneDynamicsNumber: '',
    }

    this.menuToggle = this.menuToggle.bind(this)
  }

  menuToggle () {
    this.setState(state => ({
      navigationOpen: !state.navigationOpen
    }))
  }

  componentDidMount () {
    this.setState({
      foneDynamicsNumber: window._fdNumber || '',
    })
  }

  render () {
    return (
      <nav className={ styles.nav }>
        <div className={ styles.nav__flex }>
          <p className={ styles.nav__md }>
            {this.props.alternateText
              ? (<>
                <span style={{
                  display: 'block',
                  fontSize: '2rem',
                  maxWidth: '460px',
                  margin: '0 0 1rem',
                }}>
                  Been Injured? We can help! Call now for your free expert advice!
                </span>
                Call 24/7 on <a href={
                  `tel:${
                    this.state.foneDynamicsNumber || this.props.phoneNumber
                  }`
                }>
                  { this.state.foneDynamicsNumber || this.props.phoneNumber }
                </a>
              </>)
              : (<>
                Call 24/7 on <a href={
                  `tel:${
                    this.state.foneDynamicsNumber || this.props.phoneNumber
                  }`
                }>
                  { this.state.foneDynamicsNumber || this.props.phoneNumber }
                </a>
              </>)}
          </p>
          <a
            className={ styles.nav__call }
            href={ `tel:${ this.props.phoneNumber }` }
          >
            <span>Call</span>
            <img
              src={ phone }
              style={{
                width: '3rem',
                height: '3rem',
                verticalAlign: 'top',
                display: 'inline-block'
              }}
            />
          </a>

          <Link
            to="/"
            className={ styles.nav__mobile_logo }
          >
            <img
              src={ mobileLogo }
              style={{
                height: '6rem',
              }}
            />
          </Link>

          <button
            title="Click or tap to open the menu"
            onClick={ this.menuToggle }
          >
            <span>{ this.state.navigationOpen ? `Close` : `Menu` }</span>
            <img
              src={ this.state.navigationOpen ? cancel : hamburger }
              style={{
                width: '3rem',
                height: '3rem',
                verticalAlign: 'top'
              }}
            />
          </button>
        </div>
        <div className={
          this.state.navigationOpen
            ? `${ styles.open } ${ styles.menu }`
            : `${ styles.hidden } ${ styles.menu }`
        }>
          <ul>
            <Link to="/">
              <li>Home</li>
            </Link>
            <Link to="/how-it-works">
              <li>What can you Claim?</li>
            </Link>
            <Link to="/what-we-do">
              <li>Who we Help</li>
            </Link>
            <Link to="/what-is-my-claim-worth">
              <li>What is my claim worth?</li>
            </Link>
            {/* <Link to="/fast-facts">
              <li>Facts</li>
            </Link> */}
            <Link to="/news">
              <li>News</li>
            </Link>
            <Link to="/contact-us">
              <li>Contact us</li>
            </Link>
          </ul>
        </div>
      </nav>
    )
  }
}

Navigation.propTypes = {
  phoneNumber: PropTypes.string,
}

Navigation.defaultProps = {
  phoneNumber: '1300 206 952',
}

export default Navigation
