import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO ({ description, lang, meta, title, canonical, robots }) {
  return (
    <StaticQuery
      query={ detailsQuery }
      render={ data => {
        const metaDescription =
          description || data.site.siteMetadata.description

        const metaArray = [
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]

        if (robots) {
          metaArray.push({
            name: `robots`,
            content: robots.toString(', ')
          })
        }

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={ title }
            titleTemplate={`%s | ${ data.site.siteMetadata.title }`}
            meta={ metaArray }
            link={[
              {
                rel: 'canonical',
                href: canonical,
              }
            ]}
          >
            <script type="application/ld+json">{
              `{
                "@context": "http://schema.org",
                "@type":"WebSite",
                "@id":"#website",
                "url":"https://motorinjuryhelpline.com.au/",
                "name": ${ title }
              }`
            }</script>
            <script type="application/ld+json">{
              `{
                "@context": "http://schema.org",
                "@type": "Organization",
                "url": "https://motorinjuryhelpline.com.au/",
                "sameAs": [],
                "@id": "#organization",
                "name": ${ title }
              }`
            }</script>
            <link rel="apple-touch-icon" sizes="57x57" href="images/apple-icon-57x57.png" />
            <link rel="apple-touch-icon" sizes="60x60" href="images/apple-icon-60x60.png" />
            <link rel="apple-touch-icon" sizes="72x72" href="images/apple-icon-72x72.png" />
            <link rel="apple-touch-icon" sizes="76x76" href="images/apple-icon-76x76.png" />
            <link rel="apple-touch-icon" sizes="114x114" href="images/apple-icon-114x114.png" />
            <link rel="apple-touch-icon" sizes="120x120" href="images/apple-icon-120x120.png" />
            <link rel="apple-touch-icon" sizes="144x144" href="images/apple-icon-144x144.png" />
            <link rel="apple-touch-icon" sizes="152x152" href="images/apple-icon-152x152.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="images/apple-icon-180x180.png" />
            <link rel="icon" type="image/png" sizes="192x192" href="images/android-icon-192x192.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="images/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="96x96" href="images/favicon-96x96.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="images/favicon-16x16.png" />
            <link rel="manifest" href="images/manifest.json" />
            <meta name="msapplication-TileColor" content="#ffffff" />
            <meta name="msapplication-TileImage" content="images/ms-icon-144x144.png" />
            <meta name="theme-color" content="#ffffff"></meta>
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
