import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import HorizontalForm from '../forms/horizontal'

import styles from './footer.module.scss'

const Footer = ({ hideContactForm = false }) => (
  <StaticQuery
    query={graphql`
      query FooterImage {
        file(relativePath: { eq: "motor-injury-helpline-horizontal.png" }) {
          childImageSharp {
            fluid(maxWidth: 344) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      let currentPage = () => {
        const navigation = {
          '/': 'Home',
          '/how-it-works': 'What can you Claim?',
          '/what-we-do': 'Who we Help',
          // '/fast-facts': 'Facts',
          '/news': 'News',
          '/contact-us': 'Contact Us'
        }

        const output = []

        const length = Object.keys(navigation).length

        Object.keys(navigation).map((key, index) => {
          let delimiter = index + 1 < length ? ` | ` : ``

          if (typeof window !== 'undefined' &&
              window.location &&
              window.location.pathname &&
              window.location.pathname === key) {
            output.push(
              <Link to="/" key={ index }>
                <strong>{ navigation[key] }</strong>
              </Link>
            )
          } else {
            output.push(
              <Link to={ key } key={ index }>
                { navigation[key] }
              </Link>
            )
          }

          output.push(delimiter)
        })

        return output
      }

      return <footer className={ styles.footer }>
        {
          !hideContactForm && (
            <div className={ styles.footer__form }>
              <HorizontalForm formName="footer-contact-form" />
            </div>
          )
        }
        <div className={ styles.footer__flex }>
          <div className={ styles.footer__logo_wrap }>
            <Link to="/">
              <Img
                fluid={ data.file.childImageSharp.fluid }
                title={ `Motor Injury Help Line` }
                alt={ `Motor Injury Help Line` }
              />
            </Link>
          </div>
          <div className={ styles.footer__content }>
            <nav>
              { currentPage() }
            </nav>
            <p>
              {
                `Motor Injury Help Line is an expert injury law firm. 
                Powered by Accident Legal. We offer you our no win-no 
                fee guarantee and free initial consultation. 
                You can read our `
              }
              <Link to="/privacy-policy">
                Privacy Policy
              </Link>
              { ` by following this link, and our ` }
              <Link to="/website-terms-of-use">
                Website Terms of Use
              </Link>
                &nbsp;by following this link. Icons made by Vectors-Market,
                Eleonor Wang, Freepik, Roundicons, Pixel Perfect, Icon Pond,
                Flat Icons and Icon Monk from Flaticon. Photo by&nbsp;
              <a
                href="https://unsplash.com/@ryoji__iwata"
                rel="noopener"
                target="_blank"
              >
                Ryoji Iwata
              </a>
              &nbsp;on&nbsp;
              <a
                href="https://unsplash.com/s/photos/motorway"
                rel="noopener"
                target="_blank"
              >
                Unsplash
              </a>.
              &copy;{ new Date().getFullYear() } Motor Injury Help Line.
            </p>
          </div>
        </div>
      </footer>
    }}
  />
)

export default Footer
