import React from 'react'
import PropTypes from 'prop-types'

import styles from './hform.module.scss'

const HorizontalForm = ({ formTitle, formName }) => (
  <div className={ styles.horizontalForm }>
    <h3>{ formTitle }</h3>
    <form
      name={ formName }
      method="POST"
      action="/thank-you"
      data-netlify="true"
      netlify-honeypot="nofill"
    >
      <input
        type="hidden"
        name="form-name"
        value={ formName }
      />
      <input
        name="Full Name"
        type="text"
        placeholder="Full Name"
        required
      />
      <input
        name="E-Mail or Phone"
        type="text"
        placeholder="E-Mail or Phone"
        required
      />
      <input
        type="text"
        placeholder="Don't fill this out if you're a human!"
        name="nofill"
        style={{
          position: 'absolute',
          top: '-9000px',
          left: '-9000px',
        }}
      />
      <button type="submit">Book Now</button>
    </form>
  </div>
)

HorizontalForm.propTypes = {
  formTitle: PropTypes.string,
  formName: PropTypes.string,
}

HorizontalForm.defaultProps = {
  formTitle: 'Book your 100% free, no-obligation expert case review here',
  formName: 'contact'
}

export default HorizontalForm
